"use client";
import {
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  ButtonGroup,
} from "@mui/material";
import { Card, Spinner } from "flowbite-react";
import { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";

import acshoot_firstweek from "../data/acshoot_firstweek.json";
import acshoot from "../data/acshoot.json";
import axios from "axios";
import { parse } from "node-html-parser";

const personToColor = {
  // light blue
  kidw0124: "rgb(173, 216, 230)",
  // light green
  eoaud0108: "rgb(144, 238, 144)",
  // light red
  jinhan814: "rgb(255, 182, 193)",
  none: "rgb(255, 255, 255)",
};

const tier = [
  "UR",
  "B5",
  "B4",
  "B3",
  "B2",
  "B1",
  "S5",
  "S4",
  "S3",
  "S2",
  "S1",
  "G5",
  "G4",
  "G3",
  "G2",
  "G1",
  "P5",
  "P4",
  "P3",
  "P2",
  "P1",
  "D5",
  "D4",
  "D3",
  "D2",
  "D1",
  "R5",
  "R4",
  "R3",
  "R2",
  "R1",
];

function ProblemCard({ problem }) {
  const [problemId, problemTitle, problemTier, color] = problem;
  const tierUrl = "https://static.solved.ac/tier_small/" + problemTier + ".svg";
  return (
    <Card
      href={`https://icpc.me/${problemId}`}
      target="_blank"
      style={{
        textDecoration: "none",
        width: "150px",
        height: "150px",
        minWidth: "150px",
        backgroundColor: color,
      }}
      p={1}
      m={1}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{ alignItems: "center" }}
        justifyContent={"center"}
      >
        <img src={tierUrl} alt={tier[problemTier]} width={20} height={20} />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "0.5rem",
              sm: "0.7rem",
              md: "1rem",
              xl: "1.2rem",
            },
          }}
        >
          {problemId}
        </Typography>
      </Stack>
      <Typography
        variant="body1"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          textAlign: "center",
          fontSize: {
            xs: "0.5rem",
            sm: "0.7rem",
            md: "1rem",
            xl: "1.2rem",
          },
          height: "3rem",
        }}
      >
        {problemTitle}
      </Typography>
    </Card>
  );
}

const w = 7,
  h = 7;

const problemList = [...acshoot_firstweek, ...acshoot].reduce((acc, cur, i) => {
  const week = Math.floor(i / (w * h));
  if (!acc[week]) acc[week] = [];
  acc[week].push([...cur, personToColor.none]);
  return acc;
}, []);
const idList = ["kidw0124", "eoaud0108", "jinhan814"];

const SolvePerson = ({ solves }) => {
  console.log(solves);
  return (
    <Stack direction="column" gap={2} sx={{ p: 2 }}>
      <Typography variant="h4">Solves</Typography>
      <Stack direction="row" gap={2}>
        {idList.map(
          (id) =>
            solves[id] && (
              <Stack direction="column" gap={1} key={id + "solve"}>
                <Typography variant="h6">{id}</Typography>
                <Typography variant="body1">
                  {solves[id][0]} solved, {solves[id][1]} max connected
                </Typography>
                <Typography variant="body1">
                  Score : {solves[id][0] + solves[id][1]}
                </Typography>
              </Stack>
            )
        )}
      </Stack>
    </Stack>
  );
};

function Board({ weekNum }) {
  const [week, setWeek] = useState(useParams().week - 1 || weekNum - 1);
  //   const week = 0;
  //   console.log(problemList[week]);

  const problemIdList = problemList[week]
    .map((problem) => problem[0])
    .join("|");
  const solvedSearchAPI = `https://proxy.kidw0124.workers.dev/https://solved.ac/api/v3/search/problem?query=(${problemIdList})`;
  const [move, setMove] = useState(0);
  const [cnt, setCnt] = useState(0);
  const [moveRow, setMoveRow] = useState(0);
  const [solves, setSolves] = useState({});
  useEffect(() => {
    setCnt(0);
  }, [week]);
  useLayoutEffect(() => {
    setWeek(weekNum - 1);
  }, [weekNum]);

  if (week < 0 || week >= problemList.length) week = 0;
  const problemPersonMatch = problemList[week].reduce((acc, cur) => {
    acc[cur[0]] = [];
    return acc;
  }, {});
  const problemCardList = Array.from({ length: h }, (_, i) =>
    Array.from({ length: w }, (_, j) => (
      <ProblemCard
        problem={problemList[week][((i + moveRow) % h) * w + ((j + move) % w)]}
        key={j}
        id={"problem" + i * w + j}
        cnt={cnt}
      />
    ))
  );
  const countConnectedComponentAndSolves = () => {
    const ret = {};
    for (const id of idList) {
      // count connected component with bfs
      // count solved problems
      ret[id] = [0, 0];
      const queue = [];
      const visited = new Set();
      const dx = [0, 0, 1, -1],
        dy = [1, -1, 0, 0];
      for (var i = 0; i < w; i++) {
        for (var j = 0; j < h; j++) {
          if (visited.has(i * w + j)) continue;
          if (problemList[week][i * w + j][3] === personToColor[id]) {
            visited.add(i * w + j);
            queue.push(i * w + j);
            ret[id][0]++;
            var tmp = 1;
            while (queue.length > 0) {
              var cur = queue.shift();
              var x = Math.floor(cur / w);
              var y = cur % w;
              for (var k = 0; k < 4; k++) {
                var nx = x + dx[k];
                var ny = y + dy[k];
                if (nx < 0) nx = h - 1;
                if (ny < 0) ny = w - 1;
                if (nx >= h) nx = 0;
                if (ny >= w) ny = 0;
                if (visited.has(nx * w + ny)) continue;
                console.log(nx, ny, nx * w + ny);
                if (problemList[week][nx * w + ny][3] === personToColor[id]) {
                  visited.add(nx * w + ny);
                  queue.push(nx * w + ny);
                  ret[id][0]++;
                  tmp++;
                }
              }
            }
            ret[id][1] = Math.max(ret[id][1], tmp);
          }
        }
      }
    }
    return ret;
  };

  useEffect(() => {
    const Load = async (now) => {
      if (now > idList.length) {
        setSolves(countConnectedComponentAndSolves());
        return;
      }
      if (now === idList.length) {
        // iterate over problemPersonMatch
        for (const problemId in problemPersonMatch) {
          var [time, pp] = [Math.floor(Date.now() / 1000), "none"];
          if (problemPersonMatch[problemId].length > 1) {
            // iterate id
            for (const id of problemPersonMatch[problemId]) {
              const link = `https://proxy.kidw0124.workers.dev/https://www.acmicpc.net/status?problem_id=${problemId}&language_id=-1&result_id=4&from_problem=1&user_id=${id}`;
              try {
                const res = await axios.get(link);
                if (res.status !== 200) {
                  console.error(res);
                  continue;
                }
                const root = parse(res.data);
                const problemsections =
                  root.querySelectorAll(".problem-section");
                const table = root
                  .querySelector("#status-table")
                  .querySelector("tbody");
                if (!table || table.childNodes.length === 0) {
                  continue;
                }
                // get last tr
                const tr = table.childNodes[table.childNodes.length - 1];
                // get last td
                const td =
                  tr.childNodes[tr.childNodes.length - 1].childNodes[0];
                const timestamp = parseInt(td.getAttribute("data-timestamp"));
                if (timestamp < time) {
                  time = timestamp;
                  pp = id;
                }
              } catch (e) {
                console.error(e);
              }
            }
            const problemIndex = problemList[week].findIndex(
              (problem) => problem[0] == problemId
            );
            // console.log(problemId, pp);
            // console.log(problemList[week], problemIndex);
            problemList[week][problemIndex][3] = personToColor[pp];
          }
        }
        setCnt(now + 1);
        Load(now + 1);
        return;
      }
      const person = idList[now];
      axios.get(solvedSearchAPI + " @" + person).then(async (res) => {
        await res.data.items.forEach((item) => {
          const problemId = item.problemId;
          const problemIndex = problemList[week].findIndex(
            (problem) => problem[0] === problemId
          );
          if (problemList[week][problemIndex][3] !== personToColor.none) {
            problemPersonMatch[problemId].push(person);
          } else {
            problemList[week][problemIndex][3] = personToColor[person];
            problemPersonMatch[problemId].push(person);
          }
        });
        setCnt(now + 1);
        Load(now + 1);
      });
    };
    Load(0);
  }, [solvedSearchAPI]);
  //   console.log(cnt, idList.length + 1);
  return (
    <>
      {cnt !== idList.length + 1 ? (
        <>
          <Stack direction="row" gap={2} sx={{ p: 2 }} alignItems="center">
            <Spinner />
            <Typography variant="h4">Week {week + 1} Loading...</Typography>
          </Stack>
        </>
      ) : (
        <Stack direction="column" gap={2} sx={{ p: 2 }}>
          <Stack direction="row" gap={2} sx={{ p: 2 }}>
            <Typography variant="h4">Week {week + 1}</Typography>
            <ButtonGroup>
              <Button onClick={() => setMove((move) => (move + 1) % w)}>
                Rotate Left
              </Button>
              <Button onClick={() => setMove((move) => (move + w - 1) % w)}>
                Rotate Right
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                onClick={() => setMoveRow((moveRow) => (moveRow + 1) % h)}
              >
                Rotate Up
              </Button>
              <Button
                onClick={() => setMoveRow((moveRow) => (moveRow + h - 1) % h)}
              >
                Rotate Down
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack
            direction="column"
            gap={2}
            sx={{ p: 2, m: 0 }}
            style={{ marginTop: "0px" }}
          >
            {problemCardList.map((row, i) => (
              <Stack key={i} direction="row" gap={2}>
                {row}
              </Stack>
            ))}
          </Stack>
          <SolvePerson solves={solves} />
        </Stack>
      )}
    </>
  );
}

export default Board;
