import "./App.css";
import Board from "./components/Board";
import Info from "./components/Info";

import { Routes, Route } from "react-router-dom";
import { Stack } from "@mui/material";

function App() {
  return (
    <Stack direction="column" spacing={4} className="p-4 m-4">
      <Routes>
        <Route path="/:week" element={<Board />} />
        <Route path="*" element={<Info />} />
      </Routes>
    </Stack>
  );
}

export default App;
