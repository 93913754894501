"use client";
import {
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  ButtonGroup,
} from "@mui/material";
import { Card } from "flowbite-react";
import { useEffect, useState } from "react";

import acshoot_firstweek from "../data/acshoot_firstweek.json";
import acshoot from "../data/acshoot.json";
import Board from "./Board";
const w = 7,
  h = 7;

const problemList = [...acshoot_firstweek, ...acshoot].reduce((acc, cur, i) => {
  const week = Math.floor(i / (w * h));
  if (!acc[week]) acc[week] = [];
  acc[week].push(cur);
  return acc;
}, []);
const personToColor = {
  // light blue
  kidw0124: "rgb(173, 216, 230)",
  // light green
  eoaud0108: "rgb(144, 238, 144)",
  // light red
  jinhan814: "rgb(255, 182, 193)",
  none: "rgb(255, 255, 255)",
};

const PersonCard = ({ name, id, src, idx }) => {
  return (
    <Card
      className="p-4"
      style={{
        backgroundColor: personToColor[id],
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: {
            xs: "0.5rem",
            sm: "0.7rem",
            md: "1rem",
            xl: "1.2rem",
          },
        }}
      >
        팀원 {idx}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        className=""
        alignContent="center"
        alignItems="center"
      >
        <img src={src} alt={id} className="rounded-full h-20 w-20 mr-4" />
        <Stack direction="column" spacing={1} alignContent="center">
          <Typography variant="h6">{id}</Typography>
          <Typography variant="body1">{name}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

const Info = () => {
  const startDate = new Date("2024-09-18");
  const now = new Date();
  const diff = Math.floor((now - startDate) / (1000 * 60 * 60 * 24));
  const currentWeek = Math.floor(diff / 7);
  const [week, setWeek] = useState(currentWeek);
  const personList = [
    [
      "kidw0124",
      "김동우",
      "https://static.solved.ac/uploads/profile/360x360/kidw0124-picture-1666032114968.png",
    ],
    [
      "eoaud0108",
      "김재우",
      "https://static.solved.ac/misc/360x360/default_profile.png",
    ],
    [
      "jinhan814",
      "박진한",
      "https://static.solved.ac/uploads/profile/360x360/1946271871c64c1aad789528346ac622f6af786a.png",
    ],
  ];
  return (
    <Stack direction="column" spacing={4} className="p-4 m-4">
      <Typography variant="h3" className="mb-4">
        ACShoooooooooot
      </Typography>
      <Stack direction="row" spacing={4}>
        {personList.map((person, idx) => (
          <PersonCard
            key={idx}
            idx={idx + 1}
            name={person[1]}
            id={person[0]}
            src={person[2]}
          />
        ))}
      </Stack>
      <Stack direction="row" spacing={4}>
        {Array.from({ length: currentWeek + 1 }, (_, i) => i).map((i) => (
          <Button
            key={i}
            onClick={() => setWeek(i)}
            variant={i === week ? "contained" : "outlined"}
          >
            {i + 1}주차
          </Button>
        ))}
      </Stack>
      <Board weekNum={week + 1} />
    </Stack>
  );
};

export default Info;
